var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","fill-height":""}},[_c('v-row',{attrs:{"fill-height":""}},[(_vm.orders.length > 0)?_c('v-tabs',{attrs:{"background-color":"transparent","next-icon":"mdi-arrow-right-bold-box-outline","prev-icon":"mdi-arrow-left-bold-box-outline","show-arrows":"","height":"80vh"}},[_c('v-tabs-slider',{attrs:{"color":"transparent"}}),_vm._l((_vm.orders),function(doc,key){return _c('v-tab',{key:key,staticClass:"pa-3"},[_c('v-card',{attrs:{"height":"100%","min-height":"300px","elevation":"5","color":_vm.status_color[doc.status]}},[_c('v-card-title',[_vm._v(_vm._s(doc.doc_code))]),_c('v-card-subtitle',[_vm._v(_vm._s(doc.issue_date)+" / "+_vm._s(_vm.get_timeAgo(doc.issue_date)))]),_c('v-card-text',{staticStyle:{"overflow":"auto"}},[_c('v-data-table',{staticClass:"table-cursor",attrs:{"headers":_vm.header,"items":_vm.items[doc.doc_code],"items-per-page":-1,"item-key":"order_id","hide-default-footer":"","sort-by":"name","mobile-breakpoint":"0","loading-text":"Cargando ..."},scopedSlots:_vm._u([{key:"top",fn:function(){return undefined},proxy:true},{key:"item.product_name",fn:function(ref){
var item = ref.item;
return [_c('b',{staticClass:"text-uppercase"},[_vm._v(" "+_vm._s(item.qty)+" X "+_vm._s(item.product_name)+" ")]),_c('br'),(item.note)?_c('span',{domProps:{"textContent":_vm._s(item.note)}}):_vm._e(),(item.product_options)?[_c('v-row',_vm._l((item.product_options),function(value,key){return _c('v-col',{key:key,attrs:{"cols":"12","justify":"center"}},[_c('v-data-table',{attrs:{"headers":_vm.headerSelected,"hide-default-header":"","hide-default-footer":"","items-per-page":-1,"mobile-breakpoint":"0","items":value,"item-key":"product_name","single-select":_vm.selecttype(item.product_options[key]),"dense":""},scopedSlots:_vm._u([{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(parseFloat(item.price).toLocaleString())+" ")]}}],null,true)})],1)}),1)]:_vm._e()]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(( item.qty * (parseFloat(item.price) + parseFloat(item.value_options)) ).toLocaleString())+" ")])]}}],null,true)}),_c('v-data-table',{staticClass:"table-cursor",attrs:{"headers":_vm.header_payments,"items":_vm.payments[doc.doc_code],"items-per-page":-1,"item-key":"code","hide-default-footer":"","sort-by":"name","mobile-breakpoint":"0","loading-text":"Cargando ..."},scopedSlots:_vm._u([{key:"top",fn:function(){return undefined},proxy:true},{key:"item.product_name",fn:function(ref){
var item = ref.item;
return [_c('b',{staticClass:"text-uppercase"},[_vm._v(_vm._s(item.product_name))]),_c('span',{domProps:{"innerHTML":_vm._s(item.description)}}),_c('span',[_vm._v(_vm._s(parseFloat(item.price).toLocaleString()))])]}}],null,true)})],1),_c('v-card-actions',{staticClass:"status"},[_c('v-select',{attrs:{"items":_vm.status_lst,"label":"Pendiente","solo":"","hide-details":""},on:{"change":function($event){return _vm.statusChange(doc)}},model:{value:(doc.status),callback:function ($$v) {_vm.$set(doc, "status", $$v)},expression:"doc.status"}})],1)],1)],1)})],2):_c('v-layout',{attrs:{"align-center":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","sm8":"","md4":""}},[_c('v-card',{attrs:{"flat":""}},[_c('v-sheet',{staticClass:"pa-3",attrs:{"color":"`grey`"}},[_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"max-width":"300","type":"card"}})],1),_c('v-card-text',{staticClass:"text-center"},[_c('h1',[_vm._v("No tiene Ordenes pendientes")])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }